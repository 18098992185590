<template>
  <v-dialog max-width="500" v-model="dialog.display">
    <v-card>
      <v-card-title>
        <span class="secondary-font">{{ $t("services.provision.title") }}</span>
        <v-btn class="ml-auto" icon @click="dialog.display = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-2">
        <v-text-field
          readonly
          dense
          :value="service.name"
          :label="$t('services.fields.name.title2')"
        ></v-text-field>
        <v-select
          :items="operationItems"
          :label="$t('provisions.fields.operation.title')"
          :placeholder="$t('provisions.fields.operation.placeholder')"
          v-model="form.operation"
          @input="delayTouch($v.form.operation)"
          @blur="$v.form.operation.$touch"
          :error-messages="operationErrors"
        ></v-select>
        <v-text-field
          :label="$t('provisions.fields.amount.title')"
          :placeholder="$t('provisions.fields.amount.placeholder')"
          v-model="form.price"
          autocomplete="off"
          @input="delayTouch($v.form.price)"
          @blur="$v.form.price.$touch"
          :error-messages="priceErrors"
          type="number"
          min="0"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog.display = false">{{
          $i18n.t("btn.close")
        }}</v-btn>
        <v-btn
          text
          color="primary"
          :loading="loading"
          @click.prevent="save()"
          :disabled="$v.form.$invalid"
          >{{ $i18n.t("btn.save") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FormDelayTouchMixin from "./../../mixins/form-delay-touch";
import { required, helpers, minValue } from "vuelidate/lib/validators";
import { mapGetters, mapActions } from "vuex";
export default {
  mixins: [FormDelayTouchMixin],
  props: {
    service: {
      type: Object,
      default: function () {
        return {};
      },
    },
    dialog: {
      type: Object,
      default: function () {
        return { display: false };
      },
    },
  },
  validations: {
    form: {
      operation: {
        required,
        matchOperation: (value) =>
          !helpers.req(value) || /^(CREDIT|DEBIT)$/.test(value),
      },
      price: {
        required,
        minValue: minValue(1),
      },
    },
  },
  data: () => ({
    loading: false,
    form: {
      operation: null,
      price: null,
    },
  }),
  methods: {
    ...mapActions({ notify: "notification/notify", request: "request" }),
    async save() {
      this.notify({ status: false });

      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        this.notify({ message: this.$t("form_error") });
      }

      this.loading = true;
      try {
        const response = await this.request({
          url: "provisions-services.create",
          data: {
            serviceId: this.service.id,
            price: parseInt(this.form.price),
            operation: this.form.operation,
          },
          method: "POST",
          messages: {
            201: this.$t('provisions.create.success'),
            403: true,
            404: this.$t("services.errors.404"),
            400: true,
          },
        });

        this.$emit("provisioned", response.data);
        this.resetForm();
        this.dialog.display = false;
      } catch (error) {
        // empty
      }
      this.loading = false;
    },
    resetForm() {
      this.form.price = null;
      this.form.operation = null;
      this.$v.form.$reset();
    },
  },
  computed: {
    ...mapGetters({ operationItems: "provision/operations" }),
    operationErrors() {
      const errors = [];
      if (!this.$v.form.operation.$dirty) return errors;
      if (!this.$v.form.operation.required)
        errors.push(this.$t("provisions.fields.operation.required"));
      if (!this.$v.form.operation.matchOperation)
        errors.push(this.$t("provisions.fields.operation.invalid"));
      return errors;
    },
    priceErrors() {
      const errors = [];
      if (!this.$v.form.price.$dirty) return errors;
      if (!this.$v.form.price.required)
        errors.push(this.$t("provisions.fields.amount.required"));
      if (!this.$v.form.price.minValue)
        errors.push(this.$t("provisions.fields.amount.invalid"));
      return errors;
    },
  },
};
</script>