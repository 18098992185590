<template>
  <v-dialog max-width="500" v-model="dialog.display">
    <v-card>
      <v-card-title>
        <span class="secondary-font">{{ dialog.title }}</span>
        <v-btn class="ml-auto" icon @click="dialog.display = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-2">
        <v-select
          :placeholder="$t('services.channel.fields.type.placeholder')"
          :label="$t('services.channel.fields.type.title')"
          :items="channelTypes"
          v-model="form.type"
          @input="delayTouch($v.form.type)"
          @blur="$v.form.type.$touch"
          :error-messages="typeErrors"
        ></v-select>
        <v-text-field
          :label="$t('services.channel.fields.identifier.title')"
          :placeholder="$t('services.channel.fields.identifier.placeholder')"
          v-model="form.identifier"
          @input="delayTouch($v.form.identifier)"
          @blur="$v.form.identifier.$touch"
          :error-messages="identifierErrors"
        ></v-text-field>
        <v-checkbox
          hide-details
          v-model="form.active"
          :label="$t('services.channel.fields.status.help')"
        ></v-checkbox>
        <v-checkbox
          v-model="form.owner"
          :label="$t('services.channel.fields.owner.help')"
        ></v-checkbox>
        <v-textarea
          v-model="form.description"
          no-resize
          rows="2"
          :label="$i18n.t('services.channel.fields.description.title')"
          :placeholder="
            $i18n.t('services.channel.fields.description.placeholder')
          "
          @input="delayTouch($v.form.description)"
          @blur="$v.form.description.$touch"
          :error-messages="descriptionErrors"
        ></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog.display = false">{{
          $i18n.t("btn.close")
        }}</v-btn>
        <v-btn
          text
          color="primary"
          :loading="loading.save"
          @click.prevent="save()"
          >{{ $i18n.t("btn.save") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { required, helpers, maxLength } from "vuelidate/lib/validators";
import FormDelayTouchMixin from "./../../mixins/form-delay-touch";
import { mapGetters, mapActions } from "vuex";
export default {
  mixins: [FormDelayTouchMixin],
  props: {
    dialog: {
      type: Object,
      default: function () {
        return {};
      },
    },
    form: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  validations() {
    const validations = {
      form: {
        type: {
          required,
          matchType: (val) =>
            !helpers.req(val) || this.$utils.matchChannelType(val),
        },
        identifier: {
          required,
          maxLength: maxLength(40),
        },
        description: {
          maxLength: maxLength(255),
        },
      },
    };
    if (!this.form.id) {
      validations.form.description.required = required;
    }
    return validations;
  },
  data: () => ({
    loading: {
      save: false,
    },
  }),
  methods: {
    ...mapActions({ request: "request", notify: "notification/notify" }),
    async save() {
      const self = this;
      this.notify({ status: false });
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        this.notify({ message: this.$t("form_error") });
        return;
      }

      const { id, serviceId, ...channel } = this.form;
      this.loading.save = true;
      let url;
      let data;
      if (this.form.id) {
        url = "channels.update";
        data = { ...channel, channelId: id };
      } else {
        url = "channels.create";
        data = { ...channel, serviceId: serviceId };
      }

      try {
        await this.request({
          url: url,
          data: data,
          method: "POST",
          messages: {
            200: this.dialog.success,
            201: this.dialog.success,
            403: true,
            400: true,
            500: true,
            404: this.$t("services.channel.not_found"),
            422: (error) => {
              if (error.code === "E100") {
                return self.$t("services.channel.identifier.already_exist");
              } else if (error.code === "E101") {
                return self.$t("services.not_found");
              }
            },
          },
        });
        this.$emit("success");
        this.dialog.display = false;
        this.resetForm();
      } catch (error) {
        this.$emit("errors");
      }
      this.loading.save = false;
    },
    resetForm() {
      this.$v.form.$reset();
    },
  },
  computed: {
    ...mapGetters({ channelTypes: "channel/channelTypes" }),
    identifierErrors() {
      const errors = [];

      if (!this.$v.form.identifier.$dirty) return errors;
      if (!this.$v.form.identifier.required)
        errors.push(
          this.$i18n.t("services.channel.fields.identifier.required")
        );
      if (!this.$v.form.identifier.maxLength)
        errors.push(
          this.$i18n.t("services.channel.fields.identifier.max_length", {
            max: this.$v.form.identifier.$params.maxLength.max,
          })
        );
      return errors;
    },
    typeErrors() {
      const errors = [];

      if (!this.$v.form.type.$dirty) return errors;
      if (!this.$v.form.type.required)
        errors.push(this.$i18n.t("services.channel.fields.type.required"));
      if (!this.$v.form.type.matchType)
        errors.push(this.$i18n.t("services.channel.fields.type.invalid"));
      return errors;
    },
    descriptionErrors() {
      const errors = [];

      if (!this.$v.form.description.$dirty) return errors;
      if (
        this.$utils.hasProperty(this.$v.form.description, "required") &&
        !this.$v.form.description.required
      )
        errors.push(
          this.$i18n.t("services.channel.fields.description.required")
        );
      if (!this.$v.form.description.maxLength)
        errors.push(
          this.$i18n.t("services.channel.fields.description.max_length", {
            max: this.$v.form.description.$params.maxLength.max,
          })
        );
      return errors;
    },
  },
};
</script>