<template>
  <v-dialog max-width="500" v-model="dialog.display">
    <v-card>
      <v-card-title>
        <span class="secondary-font">{{ $t("services.channel.title") }}</span>
        <v-btn class="ml-auto" icon @click="dialog.display = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-2 mb-0">
        <v-text-field
          readonly
          dense
          :value="service.name"
          :label="$t('services.fields.name.title2')"
          hide-details
        ></v-text-field>
      </v-card-text>
      <v-card-text>
        <template v-if="!loading.channel">
          <v-list class="pt-0">
            <v-list-item v-for="channel in channels" class="px-0" :key="`content-${channel.id}`">
              <v-list-item-icon class="mr-3" v-if="$utils.hasProperty(avatar, channel.type)">
                <v-icon>{{ avatar[channel.type].icon.value }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="font-weight-bold pt-2">
                <v-list-item-title>{{ channel.type }}</v-list-item-title>
                <v-list-item-subtitle class="text-body-2 font-weight-bold">
                  {{
                  channel.identifier
                  }}
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  class="text-body-2 grey--text text--darken-1"
                  v-if="channel.owner"
                >
                  {{
                  $t("services.channel.fields.owner.title")
                  }}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="text-body-2 grey--text text--darken-1">
                  <span v-if="channel.active">
                    {{
                    $t("services.channel.fields.status.enabled")
                    }}
                  </span>
                  <span v-else>
                    {{
                    $t("services.channel.fields.status.disabled")
                    }}
                  </span>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <div class="d-flex align-center">
                  <template v-if="$permission.$can('channel.update', ['user'])">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                          color="primary"
                          small
                          @click="editChannel(channel)"
                        >
                          <v-icon>mdi-pencil-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("btn.edit") }}</span>
                    </v-tooltip>
                  </template>
                  <template v-if="$permission.$can('channel.delete', ['user'])">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                          @click="deleteChannel(channel)"
                          :loading="loading.delete && item.id === channel.id"
                          small
                          color="danger"
                        >
                          <v-icon>mdi-delete-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("btn.delete") }}</span>
                    </v-tooltip>
                  </template>
                </div>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </template>
        <template v-else>
          <div class="text-center mt-2 mb-3">
            <v-progress-circular indeterminate color="secondary" width="4" size="40"></v-progress-circular>
          </div>
        </template>
      </v-card-text>
      <v-card-actions class="mt-0 pt-0" v-if="$permission.$can('channel.create', ['user'])">
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="addChannel()">
          <v-icon left>mdi-plus-circle-outline</v-icon>
          {{ $t("services.channel.btn.add") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <channel-form
      v-if="$permission.$can('channel.create', ['user']) || $permission.$can('channel.update', ['user'])"
      @success="successSaveChannelForm()"
      :dialog="dialogForm"
      :form="form"
    ></channel-form>
    <channel-delete-confirm
      v-if="$permission.$can('channel.delete', ['delete'])"
      @confirm="confirmDeleteChannel"
      :dialog="dialogDeleteConfirm"
    ></channel-delete-confirm>
  </v-dialog>
</template>

<script>
import ChannelDeleteConfirm from "./../commons/confirm";
import ChannelForm from "./channel-form";

import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    dialog: {
      type: Object,
      default: function() {
        return {};
      }
    },
    service: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data: () => ({
    loading: {
      channel: false,
      delete: false
    },
    channels: [],
    item: {},
    form: {
      identifier: null,
      description: null,
      type: null,
      serviceId: null,
      owner: true,
      id: null,
      active: false
    },
    dialogForm: {
      display: false,
      title: null,
      success: null
    },
    dialogDeleteConfirm: {
      message: null,
      display: false
    }
  }),
  methods: {
    ...mapActions({ request: "request" }),
    async getChannels() {
      this.channels = [];
      this.loading.channel = true;
      try {
        const response = await this.request({
          url: `channels.list?filter=serviceId||$eq||${this.service.id}`,
          messages: { 500: true }
        });
        this.channels = response.data;
      } catch (error) {
        //empty
      }
      this.loading.channel = false;
    },
    addChannel() {
      for (const prop in this.form) {
        if (prop === "serviceId") {
          this.form["serviceId"] = this.service.id;
          continue;
        }
        if (typeof this.form[prop] === "boolean") {
          this.form[prop] = false;
        } else {
          this.form[prop] = null;
        }
      }
      this.dialogForm = {
        display: true,
        title: this.$t("services.channel.add.title"),
        success: this.$t("services.channel.add.success")
      };
    },
    editChannel(channel) {
      for (const prop in channel) {
        if (this.$utils.hasProperty(this.form, prop)) {
          this.form[prop] = channel[prop];
        }
      }
      this.dialogForm = {
        display: true,
        title: this.$t("services.channel.edit.title"),
        success: this.$t("services.channel.edit.success")
      };
    },
    deleteChannel(channel) {
      this.item = channel;
      this.dialogDeleteConfirm = {
        display: true,
        message: this.$t("services.channel.delete.confirm")
      };
    },
    successSaveChannelForm() {
      this.getChannels();
    },
    async confirmDeleteChannel() {
      const self = this;
      if (!this.item.id) return;
      this.loading.delete = true;
      this.notify({ status: false });
      try {
        await this.request({
          url: "channels.delete",
          method: "POST",
          data: { channelId: this.item.id },
          messages: {
            403: true,
            422: errors => {
              if (errors.code === "E100") {
                return self.$t("services.channel.errors.DELETE_E100");
              }
              return;
            }
          }
        });
        this.getChannels();
      } catch (error) {
        // empty
      }
      this.loading.delete = false;
    }
  },
  watch: {
    "dialog.display"(val) {
      if (val) {
        this.getChannels();
      }
    }
  },
  computed: {
    ...mapGetters({ avatar: "conversation/avatar" })
  },
  components: {
    ChannelForm,
    ChannelDeleteConfirm
  }
};
</script>