<template>
  <div>
    <service-list></service-list>
  </div>
</template>


<script>
import ServiceList from './../../components/services/list';
export default {
  data: () => ({}),
  metaInfo() {
    return {
      title: this.$i18n.t('services.title'),
    };
  },
  components: {
    ServiceList
  }
}
</script>