<template>
  <v-dialog v-model="dialog.display" :max-width="600">
    <v-card>
      <v-card-title>
        <span class="secondary-font">{{ dialog.title }}</span>
        <v-btn class="ml-auto" icon @click="dialog.display = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-2">
        <v-text-field
          :label="$i18n.t('services.fields.name.title')"
          :placeholder="$i18n.t('services.fields.name.placeholder')"
          v-model="form.name"
          @input="delayTouch($v.form.name)"
          @blur="$v.form.name.$touch"
          :error-messages="nameErrors"
        ></v-text-field>
        <v-select
          v-model="form.billingType"
          :label="$i18n.t('services.fields.billing_type.title')"
          :placeholder="$i18n.t('services.fields.billing_type.placeholder')"
          :items="billingTypeItems"
          @input="delayTouch($v.form.billingType)"
          @blur="$v.form.billingType.$touch"
          :error-messages="billingTypeErrors"
        ></v-select>
        <v-autocomplete
          :items="ratePlanItems"
          :search-input.sync="search.ratePlan"
          v-model="form.ratePlanId"
          :label="$i18n.t('services.fields.rate_plan_id.title')"
          :placeholder="$i18n.t('services.fields.rate_plan_id.placeholder')"
          item-text="name"
          item-value="id"
          @input="delayTouch($v.form.ratePlanId)"
          @blur="$v.form.ratePlanId.$touch"
          :error-messages="ratePlanIdErrors"
        ></v-autocomplete>
        <v-textarea
          v-model="form.description"
          no-resize
          rows="2"
          :label="$i18n.t('services.fields.description.title')"
          :placeholder="$i18n.t('services.fields.description.placeholder')"
          @input="delayTouch($v.form.description)"
          @blur="$v.form.description.$touch"
          :error-messages="descriptionErrors"
        ></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog.display = false">{{
          $i18n.t("btn.close")
        }}</v-btn>
        <v-btn
          text
          color="primary"
          :loading="loading.save"
          @click.prevent="save()"
          >{{ $i18n.t("btn.save") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { required, maxLength, helpers } from "vuelidate/lib/validators";
import FormDelayTouchMixin from "./../../mixins/form-delay-touch";
import FormMixin from "./../../mixins/form";
import { mapGetters } from "vuex";

export default {
  mixins: [FormMixin, FormDelayTouchMixin],
  props: {
    dialog: {
      type: Object,
      default: function () {
        return {};
      },
    },
    form: {
      type: Object,
      default: function () {
        return {};
      },
    },
    item: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data: () => ({
    ratePlanItems: [],
    search: {
      ratePlan: null,
    },
  }),
  methods: {
    getPath() {
      return !this.dialog.update ? "services.create" : "services.update";
    },
    resetForm() {
      this.$v.form.$reset();
    },
    getMessages() {
      return {
        200: () => {
          return this.dialog.success;
        },
        201: () => {
          return this.dialog.success;
        },
        403: true,
        404: true,
        400: true,
        422: (error) => {
          const self = this;
          return self.$i18n.t(`services.errors.${error.code}`);
        },
      };
    },
  },
  validations() {
    return {
      form: {
        name: {
          required,
          maxLength: maxLength(100),
        },
        description: {
          required,
          maxLength: maxLength(255),
        },
        billingType: {
          required,
          matchBillingType: (value) =>
            !helpers.req(value) || /^(PREPAID|POSTPAID)$/.test(value),
        },
        ratePlanId: {
          required,
        },
      },
    };
  },
  watch: {
    async "search.ratePlan"(val) {
      if (!val) return;
      if (val.length < 3) return;

      this.ratePlanItems = (
        await this.request({
          url: `rate-plans.list?filter=name||$contL||${val}`,
        })
      ).data.data;
    },
    "dialog.display"(val) {
      if (val && this.dialog.update) {
        this.ratePlanItems = [];
        this.ratePlanItems.push({
          name: this.item.ratePlan.name,
          id: this.item.ratePlan.id,
        });
      }
    },
  },
  computed: {
    ...mapGetters({ billingTypeItems: "service/billingTypes" }),
    nameErrors() {
      const errors = [];

      if (!this.$v.form.name.$dirty) return errors;
      if (!this.$v.form.name.required)
        errors.push(this.$i18n.t("services.fields.name.required"));
      if (!this.$v.form.name.maxLength)
        errors.push(
          this.$i18n.t("services.fields.name.max_length", {
            max: this.$v.form.name.$params.maxLength.max,
          })
        );
      return errors;
    },
    descriptionErrors() {
      const errors = [];

      if (!this.$v.form.description.$dirty) return errors;
      if (!this.$v.form.description.required)
        errors.push(this.$i18n.t("services.fields.description.required"));
      if (!this.$v.form.description.maxLength)
        errors.push(
          this.$i18n.t("services.fields.description.max_length", {
            max: this.$v.form.description.$params.maxLength.max,
          })
        );
      return errors;
    },
    billingTypeErrors() {
      const errors = [];

      if (!this.$v.form.billingType.$dirty) return errors;
      if (!this.$v.form.billingType.required)
        errors.push(this.$i18n.t("services.fields.billing_type.required"));
      if (!this.$v.form.billingType.matchBillingType)
        errors.push(this.$i18n.t("services.fields.billing_type.invalid"));
      return errors;
    },
    ratePlanIdErrors() {
      const errors = [];

      if (!this.$v.form.ratePlanId.$dirty) return errors;
      if (!this.$v.form.ratePlanId.required)
        errors.push(this.$i18n.t("services.fields.rate_plan_id.required"));
      return errors;
    },
  },
};
</script>