<template>
  <div>
    <div class="font-weight-medium text-h6 mb-3">{{ $i18n.t("services.title") }}</div>
    <v-card class="rounded-md">
      <v-progress-linear indeterminate color="primary" width="2" size="60" v-if="loading.list"></v-progress-linear>
      <div class="d-flex align-center justify-start px-4 py-3 flex-wrap">
        <v-btn depressed @click="refreshItems()" :loading="loading.refresh" class="mr-2">
          <v-icon left>mdi-refresh</v-icon>
          {{ $i18n.t("btn.refresh") }}
        </v-btn>
        <v-btn depressed @click="filterItems()" :loading="loading.filter">
          <v-icon left>mdi-filter-variant</v-icon>
          {{ $i18n.t("btn.filter") }}
        </v-btn>
        <div class="ml-auto">
          <v-btn
            color="primary"
            @click="addService()"
            v-if="$permission.$can('service.create', ['user'])"
          >
            <v-icon left>mdi-domain-plus</v-icon>
            <span>{{ $i18n.t("btn.add") }}</span>
          </v-btn>
        </div>
      </div>
    </v-card>
    <v-card elevation="0" color="rgba(255,255,255,0)">
      <v-data-iterator
        :items="items"
        :options.sync="pagination"
        :footer-props="footerProps"
        :items-per-page="itemPerPage"
        :loading="loading.list"
        sort-by="name"
        sort-desc
        loading-text
      >
        <template v-slot:default="props">
          <v-row>
            <v-col v-for="service in props.items" :key="service.id" cols="12" sm="6" md="4">
              <v-hover v-slot="{ hover }">
                <v-card>
                  <v-card-title
                    :title="service.name"
                    class="text-body-1 font-weight-bold align-center"
                    style="height: 65px"
                  >
                    <span class="font-weight-bold">{{ service.name }}</span>
                    <v-spacer></v-spacer>
                    <template v-if="$permission.$can('service.update', ['user'])">
                      <v-btn @click="editService(service)" icon v-show="hover">
                        <v-icon color="primary">mdi-pencil-outline</v-icon>
                      </v-btn>
                    </template>
                    <template v-if="$permission.$can('service.delete', ['user'])">
                      <v-btn
                        color="danger"
                        @click="deleteService(service)"
                        small
                        icon
                        :loading="loading.delete && service.id === item.id"
                        v-show="hover"
                      >
                        <v-icon>mdi-delete-outline</v-icon>
                      </v-btn>
                    </template>
                  </v-card-title>

                  <v-divider></v-divider>
                  <v-list dense>
                    <v-list-item>
                      <v-list-item-content class="font-weight-bold">
                        {{
                        $i18n.t("services.fields.name.title")
                        }}
                      </v-list-item-content>
                      <v-list-item-content class="align-end text-body-2">
                        <span class="text-right">{{ service.name }}</span>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content class="font-weight-bold">
                        {{
                        $i18n.t("services.fields.rate_plan_id.title")
                        }}
                      </v-list-item-content>
                      <v-list-item-content class="align-end text-body-2">
                        <span class="text-right">
                          {{
                          service.ratePlan.name
                          }}
                        </span>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content class="font-weight-bold">
                        {{
                        $i18n.t("services.fields.balance.title")
                        }}
                      </v-list-item-content>
                      <v-list-item-content class="align-end text-body-2">
                        <span class="text-right">
                          {{
                          $i18n.tc(
                          "services.fields.balance.format",
                          service.balance,
                          { count: $utils.pad(service.balance, 2) }
                          )
                          }}
                        </span>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content class="font-weight-bold">
                        {{
                        $i18n.t("services.fields.rate_plan_id.title")
                        }}
                      </v-list-item-content>
                      <v-list-item-content class="align-end text-body-2">
                        <span class="text-right">
                          {{
                          service.ratePlan.name
                          }}
                        </span>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <template
                    v-if="$permission.$can('channel.list', ['user', 'service']) || $permission.$can('service.provision.create', ['user'])"
                  >
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <template v-if="$permission.$can('channel.list', ['user', 'service'])">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="primary"
                              @click="channelService(service)"
                              icon
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon>mdi-wan</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t("services.btn.channel") }}</span>
                        </v-tooltip>
                      </template>
                      <template v-if="$permission.$can('service.provision.create', ['user'])">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="primary"
                              @click="provisionService(service)"
                              icon
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon>mdi-wallet-plus-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t("services.btn.provision") }}</span>
                        </v-tooltip>
                      </template>
                    </v-card-actions>
                  </template>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </template>
        <template v-slot:loading>
          <v-card elevation="0" color="rgba(255,255,255,0)" height="100">
            <div class="fill-height d-flex align-center justify-center">
              <div class="text-center mt-5">
                <v-progress-circular indeterminate color="secondary" width="4" size="60"></v-progress-circular>
              </div>
            </div>
          </v-card>
        </template>
      </v-data-iterator>
    </v-card>
    <service-form
      v-if="$permission.$can('service.create', ['user']) || $permission.$can('service.update', ['user'])"
      :form="form"
      :item="item"
      @success="saveServiceSuccess"
      :dialog="dialog.form"
    ></service-form>
    <service-delete-confirm
      v-if="$permission.$can('service.delete', ['user'])"
      :dialog="dialog.delete"
      @confirm="confirmDeleteService"
    ></service-delete-confirm>
    <service-filter @filter="applyFilter" :configuration="filter.service" :dialog="dialog.filter"></service-filter>
    <service-provision
      v-if="$permission.$can('service.provision.create', ['user'])"
      :dialog="dialog.provision"
      @provisioned="provisionned"
      :service="item"
    ></service-provision>
    <service-channel
      v-if="$permission.$can('channel.list', ['user', 'service'])"
      :dialog="dialog.channel"
      :service="item"
    ></service-channel>
  </div>
</template>

<script>
import ServiceDeleteConfirm from "./../commons/confirm";
import ListMixin from "./../../mixins/list";
import ServiceForm from "./form";
import ServiceFilter from "./../commons/filter";
import ServiceProvision from "./provision";
import ServiceChannel from "./channel";

export default {
  mixins: [ListMixin],
  created() {
    this.setFilterServices();
    this.setShowServiceConfig();
  },
  data: () => ({
    path: "services.list",
    filter: {
      service: []
    },
    show: {
      service: {}
    },
    filters: {},
    form: {
      serviceId: null,
      name: null,
      description: null,
      billingType: null,
      ratePlanId: null
    },
    item: {},
    dialog: {
      form: {
        display: false,
        title: null,
        success: null,
        update: false
      },
      filter: {
        display: false,
        title: null,
        maxWidth: 500
      },
      provision: {
        display: false
      },
      channel: {
        display: false
      },
      delete: {
        display: false,
        message: null
      }
    }
  }),
  methods: {
    getService(item) {
      this.dialog.show.title = this.$i18n.t("services.show.title");
      this.dialog.show.display = true;
      this.item = item;
    },
    addService() {
      this.$utils.setPropertyValue(this.dialog, "form", {
        title: this.$i18n.t("services.add.title"),
        display: true,
        success: this.$i18n.t("services.add.success"),
        update: false
      });
      this.resetForm();
    },
    editService(item) {
      this.item = item;
      for (const prop in this.form) {
        if (prop === "serviceId") {
          this.form.serviceId = item.id;
        } else {
          this.form[prop] = this.$utils.getPropertyValue(item, prop);
        }
      }
      this.$utils.setPropertyValue(this.dialog, "form", {
        title: this.$i18n.t("services.edit.title"),
        display: true,
        success: this.$i18n.t("services.edit.success"),
        update: true
      });
    },
    deleteService(item) {
      this.item = item;
      this.dialog.delete = {
        display: true,
        message: this.$t("services.delete.confirm")
      };
    },
    async confirmDeleteService() {
      const self = this;
      if (!this.item.id) return;
      this.loading.delete = true;
      this.notify({ status: false });
      try {
        await this.request({
          url: "services.delete",
          method: "POST",
          data: { serviceId: this.item.id },
          messages: {
            403: true,
            422: error => {
              if (error.code === "E100") {
                return self.$t("services.errors.deleteE100");
              } else if (error.code === "E101") {
                return self.$t("services.errors.deleteE101");
              }
            }
          }
        });
        this.getItems();
      } catch (error) {
        // empty
      }
      this.loading.delete = false;
    },
    provisionService(item) {
      this.dialog.provision.display = true;
      this.item = item;
    },
    channelService(item) {
      this.dialog.channel.display = true;
      this.item = item;
    },
    provisionned(provision) {
      const findIndex = this.items.findIndex(item => this.item.id === item.id);
      if (findIndex > -1) {
        this.items[findIndex].balance = provision.new_balance;
      }
    },
    saveServiceSuccess() {
      this.refreshItems();
      this.dialog.form.display = false;
      if (this.dialog.form.update) return;
      this.resetForm();
    },
    resetForm() {
      for (const prop in this.form) {
        this.form[prop] = null;
      }
    },
    filterItems() {
      this.dialog.filter.display = true;
      this.dialog.filter.title = this.$i18n.t("services.filter.title");
    },
    setFilterServices() {
      this.filter.service = [
        {
          name: "name",
          type: {
            name: "VTextField",
            operator: "$contL",
            options: {
              label: this.$i18n.t("services.fields.name.title"),
              placeholder: this.$i18n.t("services.fields.name.placeholder"),
              clearable: true
            }
          }
        }
      ];
    },
    setShowServiceConfig() {
      this.show.service = {
        data: item => {
          return item;
        },
        fields: {
          name: {
            type: "span",
            title: this.$i18n.t("services.fields.name.title"),
            divider: true
          },
          billingType: {
            type: "span",
            title: this.$i18n.t("services.fields.billing_type.title"),
            parser: data => {
              if (data === "PREPAID") {
                return this.$i18n.t("services.fields.billing_type.prepaid");
              } else if (data === "POSTPAID") {
                return this.$i18n.t("services.fields.billing_type.postpaod");
              }
              return null;
            },
            divider: true
          },
          balance: {
            type: "span",
            title: this.$i18n.t("services.fields.balance.title"),
            display: (data, item) => {
              return item.billingType === "PREPAID";
            },
            parser: data => `${data} XAF`,
            divider: true
          },
          "ratePlan.name": {
            type: "span",
            title: this.$i18n.t("services.fields.rate_plan_id.title"),
            divider: true
          },
          description: {
            type: "span",
            title: this.$i18n.t("services.fields.description.title")
          }
        }
      };
    }
  },
  components: {
    ServiceForm,
    ServiceFilter,
    ServiceProvision,
    ServiceChannel,
    ServiceDeleteConfirm
  }
};
</script>